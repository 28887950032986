import React from "react";

import {
  CardBody,
  TextBox,
  Row,
  detectDevice,
  LineSkeleton,
  Image,
} from "@sberdevices/plasma-ui";
import { Container } from "@sberdevices/plasma-core";

import { AssistantContext } from "../helpers/AssistantContext";
import { sendText } from "../helpers/Assistant";
import { formatMoney } from "../helpers/TextUtils";
import TimerComponent from "../helpers/TimerComponent";
import { setPageCommands, sendAction } from "../helpers/Assistant";
import { isTouch } from "../helpers/ScreenUtils";
import { isFullHD, isSmallScreen } from "../helpers/ScreenUtils";
import { getDisallowedDirections } from "../helpers/Navigate";

import { isBingo } from "./constants";

import { StyledCarousel } from "../style/style";
import {
  StyledCarouselWrapper,
  StyledSectionTitle,
  GameCategoryStyledCarouselItem,
  GameCategoryCardParagraph1Colored,
  GameCategoryCardContent,
  GameCategoryCard,
  GameCategoryTextBoxBigTitle,
  GameCategoryTextBoxSubTitle,
  GameCategoryCardParagraph1,
} from "./styles/game-category-styles";

class GameCategory extends React.Component {
  static contextType = AssistantContext;
  constructor(props) {
    super(props);
    this.state = {
      indexCarousel: 0,
      games: this.props.games,
      disabled: {},
    };
  }

  clickGame(gameName) {
    if (this.state.disabled[gameName]) {
      return;
    }

    const itemIndex = Number(this.state.indexCarousel);
    const game = this.state.games[itemIndex];
    const { draw_id, name } = game;

    if (draw_id && name) {
      sendAction(this.context, {
        action_id: "OPEN_DRAW",
        parameters: { draw_id, game_id: name },
      });

      return;
    }

    sendText(this.context, gameName);
  }

  componentWillUnmount() {
    setPageCommands(this.context, {});
  }

  //  componentWillReceiveProps(nextProps){
  //    if(!nextProps.messagePopupWasOpened && isRefFromPopup()){
  //       if(this.props.gamesCategoryId==0){
  //         this.initialFocus?.focus();
  //       }
  //    }
  //   }

  componentDidMount() {
    if (this.props.gamesCategoryId == 0) {
      this.initialFocus?.focus();
    }

    this.setCommands();
  }

  getPaddingStart() {
    if (isFullHD()) {
      return "5.8%";
    }
    if (isSmallScreen()) {
      return "4.8%";
    }
    if (detectDevice() == "mobile") {
      return "";
    }
  }
  setNewDrawInfo(action) {
    const gameName = Object.keys(action.payload.draws)[0];
    const newDraw = action.payload.draws[gameName][0];
    if (newDraw.stopSalesDate && new Date(newDraw.stopSalesDate) < new Date()) {
      setTimeout(() => this.timerEnd(gameName), 2000);
    }
    let oldGamesInfo = this.state.games;
    console.log(action.payload.draws[gameName]);
    if (action.payload.draws[gameName].length != 0) {
      if (action.payload.draws[gameName][0].length !== 0) {
        //let targetGame = this.state.games.filter(game => game.name == gameName)
        //targetGame.draw = action.payload.draws[gameName][0]
        oldGamesInfo.map((game, index) => {
          if (game.name == gameName) {
            game.draw = newDraw;
          }
        });
        this.setState({
          games: oldGamesInfo,
          disabled: { ...this.state.disabled, [gameName]: false },
        });
      }
    }
  }

  setCommands() {
    this.context.page_commands["SEND_DRAWS"] = (action) => {
      this.setNewDrawInfo(action);
    };
  }
  timerEnd = (gameName) => {
    this.setState({ disabled: { ...this.state.disabled, [gameName]: true } });
    sendAction(this.context, {
      action_id: "GET_DRAWS",
      parameters: { game_ids: [gameName] },
    });
    this.setCommands();
  };
  render() {
    return (
      <Container style={{ paddingLeft: "0px", paddingRight: "0px" }}>
        <Row>
          <StyledSectionTitle active={false}>
            {this.props.category_name}
          </StyledSectionTitle>
        </Row>
        <Row
          style={{
            display: "flex",
            justifyContent: "space-around",
            marginLeft: "0px",
          }}
        >
          <StyledCarouselWrapper>
            <StyledCarousel
              paddingStart={this.getPaddingStart()}
              // paddingEnd="3.5em"
              detectThreshold={0.5}
              scrollSnapType="mandatory"
              //  onIndexChange={(index) => this.setState( {indexCarousel: index} )}
              detectActive={true}
              scrollAlign="center"
              axis="x"
              style={{ paddingLeft: detectDevice() != "mobile" && "0px" }}
              index={this.state.indexCarousel}
            >
              {this.state.games.map((game, i, arr) => (
                <GameCategoryStyledCarouselItem key={game.name}>
                  <GameCategoryCard
                    disabled={this.state.disabled[game.name]}
                    outlined={true}
                    //autoFocus={true}
                    roundness={"12"}
                    ref={(card) => {
                      if (!this.initialFocus) {
                        this.initialFocus = card;
                      }
                    }}
                    tabIndex={this.props.messagePopupWasOpened ? -1 : 0}
                    id={i}
                    data-disallowed-directions={getDisallowedDirections(
                      i,
                      arr.length
                    )}
                    onFocus={(e) => {
                      this.setState({ indexCarousel: e.target.id });
                    }}
                    onClick={(e) => isTouch() && this.clickGame(game.name)}
                    onKeyUp={(e) =>
                      e.key === "Enter" && this.clickGame(game.name)
                    }
                  >
                    <CardBody>
                      <Image
                        src={`/main_images/${this.props.prefix || ""}${
                          game.name
                        }.png`}
                        style={{ position: "absolute", height: "100%" }}
                        alt="Картинка для примера фоном"
                        base="div"
                      />
                      <GameCategoryCardContent>
                        <TextBox>
                          {game.draw.superPrize == 0 ? (
                            <div style={{ width: "75%" }}>
                              <LineSkeleton size="footnote1" roundness={12} />
                            </div>
                          ) : (
                            <GameCategoryTextBoxBigTitle>
                              {formatMoney(game.draw.superPrize)}
                            </GameCategoryTextBoxBigTitle>
                          )}

                          <GameCategoryTextBoxSubTitle>
                            Суперприз
                          </GameCategoryTextBoxSubTitle>
                          {game.draw.betCost == 0 ? (
                            <div style={{ width: "75%" }}>
                              <LineSkeleton size="footnote1" roundness={12} />
                            </div>
                          ) : (
                            <GameCategoryCardParagraph1>
                              Билеты {isBingo(game.name) ? "по" : "от"}{" "}
                              {formatMoney(game.draw.betCost)}
                            </GameCategoryCardParagraph1>
                          )}

                          <GameCategoryCardParagraph1Colored>
                            <TimerComponent
                              gamesCategoryPage={true}
                              timerEndReaction={() => this.timerEnd(game.name)}
                              targetTime={game.draw.stopSalesDate}
                            />
                          </GameCategoryCardParagraph1Colored>
                        </TextBox>
                      </GameCategoryCardContent>
                    </CardBody>
                  </GameCategoryCard>
                </GameCategoryStyledCarouselItem>
              ))}
            </StyledCarousel>
          </StyledCarouselWrapper>
        </Row>
      </Container>
    );
  }
}

export default GameCategory;
